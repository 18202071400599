$white : #fff;
$red: #cd3426;
// .map-container{
//   overflow:hidden;
//   padding-bottom:56.25%;
//   position:relative;
//   height:0;
// }
.map-container iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
.responsive-information-contact{
  display: flex!important;
  flex-direction: row;
}
@media screen and (max-width: 600px){
  .responsive-information-contact{
    display: inline!important;
  }
}
.color-card-contact{
  color: $red;
}

// .grecaptcha-badge { visibility: hidden; }