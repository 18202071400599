.navbar {
  background-color: transparent;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: absolute;
  top: 0;
  z-index: 20;
  left: 0;
  right: 0;
}

.logo-navbar {
  width: 100px;
}
.btn-lang-active {
  color: #fff;
  border-bottom: 1px solid #fff;
}
.btn-lang-active :hover {
  color: #fff !important;
  border-bottom: 1px solid #fff;
}

.btn-lang-active-custom {
  color: #000 !important;
  border-bottom: 1px solid #000 !important;
}
.btn-lang-active-custom :hover {
  color: #000 !important;
  border-bottom: 1px solid #000 !important;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
  color: #fff;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 1rem;
}

.nav-links {
  color: #fff !important;
  text-decoration: none !important;
  padding: 0.5rem 0.5rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  // text-transform: uppercase;
}
.nav-links-custom {
  color: #000 !important;
  text-decoration: none !important;
  padding: 0.5rem 0.5rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  // text-transform: uppercase;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 0.5rem;
}
.nav-item-custom {
  line-height: 40px;
  margin-right: 0.5rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item-custom:hover:after {
  width: 100%;
  background: #e83e30 !important;
}
.nav-item-custom:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #fff !important;
}

.nav-item .active {
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
}
.nav-item-custom .active {
  color: #e83e30 !important;
  border-bottom: 1px solid #e83e30 !important;
}
.circle-icons {
  border: 1px solid #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.circle-icons-custom {
  border: 1px solid #000;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.nav-icon {
  display: none;
}
.logo-navbar {
  width: 100%;
  max-width: 50%;
  //   max-width: 70%;

}
.sticky-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
}
@media screen and (max-width: 1100px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    overflow: hidden;
    background: #000;
    height: 100vh;
    overflow: hidden;
  }
  .sticky-nav {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #000 !important;
    height: 100px;
    // overflow-y: scroll;
    z-index: 9998 !important;
  }

  .nav-item-custom .active {
    color: #fff !important;
    border-bottom: 1px solid #fff !important;
  }
  .nav-item .active {
    color: #fff !important;
    border-bottom: 1px solid #fff !important;
  }
  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }

  .nav-item:hover:after {
    width: 100%;
    background: #fff !important;
  }

  .nav-menu.active {
    background: #000 !important;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item {
    color: #fff !important;
    border: none;
  }
  .nav-item .active {
    color: #fff !important;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
    color: #fff !important;
  }
  .nav-links-custom {
    padding: 1.5rem;
    width: 100%;
    display: table;
    color: #fff !important;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 10px;
    right: 0;
    transform: translate(-100%, 0%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
  .resposive-lang {
    position: absolute !important;
    top: -10px !important;
    left: 0px !important;
    right: 0px !important;
    z-index: 9999 !important;
  }
}
@media (max-width: "600px") {
  .logo-navbar {
    display: block;
    position: absolute;
    width: 50%;
    top: 10px;
  }
  .color-mobile {
    color: #fff !important;
  }
}
