.bg {
  bottom: 0;
  filter:contrast(.9) brightness(.6);
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 0px;
  position: relative;
}

.content {
  align-items: center;
  font-style: bold !important;
  bottom: 0;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 0;
  padding: 20vw;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  font-size: 25px;
  font-weight: 200;
  // backdrop-filter: contrast(0.9) brightness(0.7);
}
.title-principal {
  font-size: 95px;
  font-weight: 600;
}
.desc-principal {
  font-size: 35px;
  font-weight: 300 !important;

  position: relative;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.desc-principal-home {
  font-size: 35px;
  font-weight: 300 !important;
  position: relative;
  // background: #e83e30;
  opacity: 0;
  animation: fadeIn 3s ease-in-out forwards;
}

// .desc-principal-home:after {
//   content: "";
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 0;
//   height: 0;
//   border-left: 40px solid white;
//   border-top: 53px solid transparent;
//   border-bottom: 52.5px solid transparent;
// }

// .desc-principal-home:before {
//   content: "";
//   position: absolute;
//   right: -40px;
//   bottom: 0;
//   width: 0;
//   height: 0;
//   border-left: 40px solid #e83e30;
//   border-top: 53px solid transparent;
//   border-bottom: 52.5px solid transparent;
// }
@media (max-width: "1200px") {
  .title-principal {
    font-size: 50px;
    font-weight: 600;
  }
  .content {
    font-size: 20px;
  }
  .desc-principal {
    font-size: 25px;
    font-weight: 300 !important;
  }
  .desc-principal-home {
    font-size: 25px;
    font-weight: 300 !important;
  }
  .bg {
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    filter: contrast(0.9) brightness(0.7);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    padding-top: 163.25%;
    height: 0px;
    position: relative;
  }
}

.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 40px;
  height: 40px;
  top: 90%;
  left: 50%;
  transform: translate(-60%, -90%);
  // transform: (-85%);
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
}

.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}

@media (max-width: "900px") {
  .arrow {
    // width: 40px;
    // height: 40px;
    // top: 75%;
    // transform: (-75%);
    // margin: -20px 0 0 -20px;
    // -webkit-transform: rotate(45deg);
    // border-left: none;
    // border-top: none;
    // border-right: 2px #fff solid;
    // border-bottom: 2px #fff solid;
    display: none;
  }
  .desc-principal-home:after {
    display: none;
  }
  .desc-principal-home:before {
    display: none;
  }

  .title-principal {
    font-size: 44px;
  }
  .desc-principal {
    font-size: 22px;
  }
  .desc-principal-home {
    font-size: 22px;
  }
  .responsive-btn-home {
    width: 100% !important;
  }
}
.circle-home {
  background-color: #e83e30;
  border-radius: 50%;
  height: 28px;
  padding: 15px;
  width: 28px;
}
