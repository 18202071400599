.loader{
    position: fixed;
    left: 10px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }

  @media (max-width:600px) {
    .loader{
    display: "flex";
    width: "50px"!important;
    position: "absolute";
    top: "50%";
    left: "50%";
    right: "50%";
  }
    
  }