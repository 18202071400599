.modal-cookie {
	position: fixed;
	top:10%;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	// background-color: rgba(#000, 0.25);
    z-index: 9999;
}
.footer-cookiebar{
	background-color: rgb(238, 238, 238);
}
.overlay{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(3px);
	z-index: 9998;
	display: none;
}
.modal-container {
	max-height: 100vh;
	max-width: 850px;
    margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	background-color: #fff;
	border-radius: 16px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	box-shadow: 0 15px 30px 0 rgba(#000, 0.25);
	@media (max-width: 600px) {
		width: 90%;
		max-height: 70vh;

	}
}

.modal-container-header {
	padding: 16px 32px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modal-container-title {
	display: flex;
	align-items: center;
	gap: 8px;
	line-height: 1;
	font-weight: 700;
	font-size: 1.125;
	color: #000;
	svg {
		width: 32px;
		height: 32px;
		color: #28a745;
	}
}

.modal-container-body {
	padding: 24px 32px 51px;
	overflow-y: auto;
}

.rtf {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 600;
	}

	h1 {
		font-size: 1.5rem;
		line-height: 1.125;
	}

	h2 {
		font-size: 1.2rem;
		line-height: 1;
	}

	h3 {
		font-size: 1rem;
		line-height: 1.5;
	}

	& > * + * {
		margin-top: 1em;
	}

	& > * + :is(h1, h2, h3) {
		margin-top: 2em;
	}

	& > :is(h1, h2, h3) + * {
		margin-top: 0.75em;
	}

	ul,
	ol {
		margin-left: 20px;
		list-style-position: inside;
	}

	ol {
		list-style: numeric;
	}

	ul {
		list-style: disc;
	}
}

.modal-container-footer {
	padding: 20px 32px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-top: 1px solid #ddd;
	gap: 12px;
	position: relative;
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: -51px;
		left: 24px;
		right: 24px;
		height: 50px;
		flex-shrink: 0;
		background-image: linear-gradient(to top, rgba(#fff, 0.75), transparent);
		pointer-events: none;
	}
}

.button {
	padding: 12px 20px;
    width: 50%;
    margin: 0 auto;
	border-radius: 8px;
	background-color: transparent;
	border: 0;
	font-weight: 600;
	cursor: pointer;
	transition: 0.15s ease;

	&.is-ghost {
		&:hover,
		&:focus {
			background-color: #dfdad7;
		}
	}

	&.is-primary {
		background-color: #28a745;
		color: #fff;
		&:hover,
		&:focus {
			background-color: #27a745;
		}
	}
}

.icon-button {
	padding: 0;
	border: 0;
	background-color: transparent;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	cursor: pointer;
	border-radius: 8px;
	transition: 0.15s ease;
	svg {
		width: 24px;
		height: 24px;
	}

	&:hover,
	&:focus {
		background-color: #dfdad7;
	}
}



.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

}

hr {
    z-index: 9999;
    opacity: 0.2;
}

h4 {
    font-size: 20px;
    color: #333;
    opacity: 0.8;
}

/* Hide default HTML checkbox */
.switch input {
    display: none;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #52AF52;
}

input:focus+.slider {
    box-shadow: 0 0 1px #52AF52;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.slider .disable {
    display: none;
}

/* Switch active */
.slider-disable {
    opacity: 0.8;
    position: absolute;
    cursor: default;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider-disable:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input+.slider-disable {
    background-color: #52AF52;
}

input+.slider-disable:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider-disable.round {
    border-radius: 34px;
}

.slider-disable.round:before {
    border-radius: 50%;
}

.slider-disable .disable {
    display: none;
}
.link-policy {
    color: rgb(0, 102, 204);
}



$animation: all 0.3s cubic-bezier(0.770, 0.000, 0.20, 0.85);

.closeModal {
  width:40px;
  height:40px;
  border-radius:40px;
  position:relative;
  z-index:1;
  cursor:pointer;
  &:before {
    content:'+';
    color:#000;
    position:absolute;
    z-index:2;
    transform:rotate(45deg);
    font-size:50px;
    line-height:1;
    top:-5px;
    left:6px;
    transition: $animation;
  }
  &:after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    right: 0;
    width:100%;
    height:100%;
    border-radius:100%;
    background:#000;
    z-index:1;
    transition: $animation;
    transform:scale(0.01);
  }
  &:hover:after {
    transform:scale(1);
  }
  &:hover:before {
    transform:scale(0.8) rotate(45deg);
    color:#fff;
  }
}

.wrapperButton{
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    width: 50%;
    margin-top: 20px;
}
.btnPreferenze{
    width: 20%;
    background-color: #6c757d;
    border-radius: 5px;
    &:hover{
        background-color: #6c757d;
    }
}
.btnAcceptAll{
    margin-right: 20px;
    border-radius: 5px;
}
.btnNecessary{
    margin-right: 20px;
    border-radius: 5px;
    background-color: #007bff;
}

@media (max-width: 600px) {
    .preferences{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .btnPreferenze{
        width: 80%;
        margin-top: 5px;
    }
    .btnAcceptAll{
        width: 80%;
        margin-top: 5px;
    }
    .wrapperButton{
        display: inline;
    }
	.div-button-cookiebar{
		display: inline!important;
		text-align: center;
		width: 100% !important;
	}
}
