@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  font-family: "Montserrat", sans-serif;
  // font-family: 'Roboto', sans-serif;
  // font-family: 'Libre Baskerville', serif;
}
// .container-fluid {
//   position: relative;
//   width: 100%;
//   .dummy {
//     padding-top: 56.25%;
//     background-size: cover;
//     background-repeat: no-repeat;
//     // 16:9 aspect ratio
//   }
// }
.bg {
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  filter: contrast(0.8) brightness(0.8);
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
}

.content {
  align-items: center;
  bottom: 0;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 0;
  padding: 20vw;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  font-size: 25px;
  font-weight: 200;
}
.title-principal {
  font-size: 60px;
  font-weight: 600;
}
.title-about-us {
  color: #e83e30;
  font-size: 44px;
  font-weight: 600;
}

@media (max-width: "900px") {
  .title-principal {
    font-size: 45px;
    font-weight: 600;
  }
  .content {
    font-size: 20px;
  }
  .bg {
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    filter: contrast(0.9) brightness(0.9);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    padding-top: 163.25%;
    height: 0px;
    position: relative;
  }
}

.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 40px;
  height: 40px;
  top: 85%;
  transform: (-85%);
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
}

.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}

@media (max-width: "900px") {
  .arrow {
    width: 40px;
    height: 40px;
    top: 75%;
    transform: (-75%);
    margin: -20px 0 0 -20px;
    -webkit-transform: rotate(45deg);
    border-left: none;
    border-top: none;
    border-right: 2px #fff solid;
    border-bottom: 2px #fff solid;
  }
}
.circle-home {
  background-color: #e83e30;
  border-radius: 50%;
  height: 28px;
  padding: 15px;
  width: 28px;
}

.wrapper {
  overflow: hidden;
}

.sliding-background {
  height: 500px;
  width: 5076px;
}
.div_1 {
  position: relative; /* added */
  width: 100%;
  background: #fbfbfb;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.1);
  height: 700px;
  background-color: #e83e30;
}
.div_2 {
  position: absolute; /* added */
  height: 110% !important;
  width: 95% !important;
  top: -5%; /* modified */
  left: 2.5%; /* modified */
  background: #fbfbfb;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.1);
}
.image-bg {
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 0px;
  position: relative;
}

// Sezione partner loghi
.section {
  background-color: transparent !important;
}
.square-holder {
  padding: 10px;
  //border: 1px solid #cecece;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  //background-color:#f1f1f1;
  min-height: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.square-holder img {
  max-width: 100%;
  // filter: grayscale(100%);
  // transition: all 0.3s;
}

.square-holder:hover img {
  filter: none;
}

.image-card-clienti {
  width: 60%;
  display: flex;
  height: auto;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 600px) {
  .image-card-clienti {
    width: 100%;
    display: inline-block;
    height: auto;
    object-fit: cover;
    object-position: center;
  }
  .square-holder img {
    max-width: 80%;
    //filter: grayscale(100%);
    //transition: all 0.3s;
  }
  .square-holder {
    padding: 0 !important;
    border: 1px solid #cecece;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    //background-color:#f1f1f1;
    height: auto;
    width: 100%;
  }
}
