


// /* Main styles */
// @import url(https://fonts.googleapis.com/css?family=Open+Sans:800);

// .text {
//   fill: none;
//   stroke-width: 6;
//   stroke-linejoin: round;
//   stroke-dasharray: 70 330;
//   stroke-dashoffset: 0;
//   -webkit-animation: stroke 6s infinite linear;
//   animation: stroke 6s infinite linear;
// }

// .text:nth-child(5n + 1) {
//   stroke: #F2385A;
//   -webkit-animation-delay: -1.2s;
//   animation-delay: -1.2s;
// }
// .text:nth-child(5n + 2) {
//   stroke: #F5A503;
//   -webkit-animation-delay: -2.4s;
//   animation-delay: -2.4s;
// }

// .text:nth-child(5n + 3) {
//   stroke: #E9F1DF;
//   -webkit-animation-delay: -3.6s;
//   animation-delay: -3.6s;
// }

// .text:nth-child(5n + 4) {
//   stroke: #56D9CD;
//   -webkit-animation-delay: -4.8s;
//   animation-delay: -4.8s;
// }

// .text:nth-child(5n + 5) {
//   stroke: #3AA1BF;
//   -webkit-animation-delay: -6s;
//   animation-delay: -6s;
// }

// @-webkit-keyframes stroke {
//   100% {
//     stroke-dashoffset: -400;
//   }
// }

// @keyframes stroke {
//   100% {
//     stroke-dashoffset: -400;
//   }
// }

// .error{
//   background: #111;
//   background-size: .2em 100%;
//   font: 14.5em/1 Open Sans, Impact;
//   text-transform: uppercase;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   position: relative;
// }

// .svg-error {
//   position: relative;
//   width: 80%;
//   height: 100%;
//   margin: 0 auto;
//   text-align: center;
//   justify-content: center;
//   display: flex;
//   margin-top: 120px;
// }

.error-404 { 
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAxMC8yOS8xMiKqq3kAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAABHklEQVRIib2Vyw6EIAxFW5idr///Qx9sfG3pLEyJ3tAwi5EmBqRo7vHawiEEERHS6x7MTMxMVv6+z3tPMUYSkfTM/R0fEaG2bbMv+Gc4nZzn+dN4HAcREa3r+hi3bcuu68jLskhVIlW073tWaYlQ9+F9IpqmSfq+fwskhdO/AwmUTJXrOuaRQNeRkOd5lq7rXmS5InmERKoER/QMvUAPlZDHcZRhGN4CSeGY+aHMqgcks5RrHv/eeh455x5KrMq2yHQdibDO6ncG/KZWL7M8xDyS1/MIO0NJqdULLS81X6/X6aR0nqBSJcPeZnlZrzN477NKURn2Nus8sjzmEII0TfMiyxUuxphVWjpJkbx0btUnshRihVv70Bv8ItXq6Asoi/ZiCbU6YgAAAABJRU5ErkJggg==);}
.error-template {padding: 30px 10px;text-align: center;}
.error-actions {margin-top:15px;margin-bottom:15px;}
// .error-actions .btn { margin-right:10px; }