// .canvas-wrapper {
//   display: flex;
//   width: 100%;
//   align-items: stretch;
//   justify-content: center;
//   height: 100%;
// }
// .canvas-wrapper .canvas + .canvas {
//   margin-left: 40px;
// }

.canvas {
  position: relative;
  display: block;
  width: 100%;
  height: 600px;
}

// .canvas_border {
//   position: absolute;
//   top: 40px;
//   left: -40px;
//   height: 100%;
//   width: 100%;
//   z-index: 0;
// }
// .canvas_border svg {
//   height: 100%;
//   width: 100%;
// }

// .rect-gradient {
//   stroke-dasharray: 2000;
//   stroke-dashoffset: 2000;
//   -webkit-animation: erase-line 1s ease-in-out forwards;
//   animation: erase-line 1s ease-in-out forwards;
// }

.canvas_img-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background: #fff;
  border: 1px solid #a6a6a6;
}

.canvas_img {
  // transform: scale3d(1, 1, 1);
  // opacity: 1;
  // width: 100%;
  // height: 100%;
  // object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #fff;

  //   max-width: 200px;
  //   max-height: 200px;
}

.canvas_copy {
  position: absolute;
  bottom: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: #fff;
  z-index: "100px";
}

.canvas_copy_pre {
  position: absolute;
  // bottom: 0;
  bottom: -5%; //ripristinare a 0 per effetto originale
  left: 50%;
  transform: translate(-50%, -45%);
  // text-transform: uppercase;
  color: #484c61;
  z-index: "100px";
  background-color: #fff;
  width: 70%;
  height: 120px;
  border: 2px solid #a6a6a6;
  transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
}

.canvas_copy--left {
  left: -25%;
}

.canvas_copy_title {
  font-size: 55px;
  line-height: 1;
  display: block;
  text-align: center;
  transform: translateX(-80px);
  transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  color: #fff;
}
.canvas_copy_title:nth-child(1) {
  transition-delay: 0.1s;
}
.canvas_copy_title:nth-child(2) {
  transition-delay: 0.2s;
}

.canvas_copy_subtitle {
  display: block;
  transform: rotate(270deg) translateY(-100%) translateX(calc(-100% - 80px));
  transform-origin: top left;
  position: absolute;
  left: 0;
  top: 8px;
  font-size: 24px;
  font-weight: 900;
  transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.35s;
}

.canvas_copy_details {
  display: block;
  transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.14s;
  transform: translateX(-80px);
}
.canvas_copy_title_pre {
  font-size: 32px;
  line-height: 1;
  display: block;
  text-align: center;
  transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  color: #484c61;
}
.canvas_copy_title_pre:nth-child(1) {
  transition-delay: 0.1s;
}
.canvas_copy_title_pre:nth-child(2) {
  transition-delay: 0.2s;
}

.canvas_copy_subtitle_pre {
  display: block;
  transform: rotate(270deg) translateY(-100%) translateX(calc(-100% - 80px));
  transform-origin: top left;
  position: absolute;
  left: 0;
  top: 8px;
  font-size: 24px;
  font-weight: 900;
  transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.35s;
}

.canvas_copy_details_pre {
  display: block;
  text-align: left;
  transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.14s;
  transform: translateX(-80px);
  // text-transform: capitalize;
  font-size: 20px;
  font-weight: 400;
  padding: 10px;
  text-align: center;
}

.canvas_border,
.canvas_img-wrapper,
.canvas_img {
  transition: all 0.25s ease-in-out 0s;
}

// .canvas_border, .canvas_img-wrapper {
//   transform: rotate(-10deg) skew(-10deg);
// }

.canvas_copy_title_pre,
.canvas_copy_subtitle_pre,
.canvas_copy_details_pre {
  opacity: 1;
}
.canvas_copy_title,
.canvas_copy_subtitle,
.canvas_copy_details {
  opacity: 0;
}

.canvas:hover .canvas_img-wrapper {
  background: linear-gradient(to right, #434343 0%, black 100%);
}
.canvas:hover .canvas_img {
  opacity: 0.4;
}

.canvas:hover .canvas_copy_title,
.canvas:hover .canvas_copy_subtitle,
.canvas:hover .canvas_copy_details {
  opacity: 1;
}
.canvas:hover .canvas_copy_title_pre,
.canvas:hover .canvas_copy_subtitle_pre,
.canvas:hover .canvas_copy_details_pre,
.canvas:hover .canvas_img_pre,
.canvas:hover .canvas_copy_pre {
  opacity: 1; //per riprist7inare l'effetto normale, reimpostare a 0
}
// .canvas:hover .canvas_copy_title_pre {
//   transform: rotate(-14deg) skew(-14deg) scale(0.96);
// }

// .canvas:hover .canvas_img {
//   transform: scale3d(1, 1, 1);
// }
.canvas:hover .canvas_copy_title,
.canvas:hover .canvas_copy_details {
  transform: translateX(0);
}
.canvas:hover .canvas_copy_subtitle {
  transform: rotate(270deg) translateY(-100%) translateX(-100%);
}
.canvas:hover .rect-gradient {
  -webkit-animation: draw-line 3s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  animation: draw-line 3s cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

@-webkit-keyframes draw-line {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-line {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes erase-line {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 2000;
  }
}
@keyframes erase-line {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 2000;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.canvas .canvas_copy_title_pre,
.canvas .canvas_copy_subtitle_pre,
.canvas .canvas_copy_details_pre,
.canvas .canvas_img_pre {
  opacity: 1;
}
.canvas .canvas_copy_title_pre,
.canvas .canvas_copy_details_pre {
  transform: translateX(0);
}
.canvas .canvas_copy_subtitle_pre {
  transform: rotate(270deg) translateY(-100%) translateX(-100%);
}

@media (max-width: "600px") {
  .canvas .canvas_copy_title,
  .canvas .canvas_copy_subtitle,
  .canvas .canvas_copy_details,
  .canvas .canvas_img {
    opacity: 1;
  }
  .canvas:hover .canvas_copy_title_pre,
.canvas:hover .canvas_copy_subtitle_pre,
.canvas:hover .canvas_copy_details_pre,
.canvas:hover .canvas_img_pre,
.canvas:hover .canvas_copy_pre {
  opacity: 0; //per riprist7inare l'effetto normale, reimpostare a 0
}
  .canvas .canvas_copy_title,
  .canvas .canvas_copy_details {
    transform: translateX(0);
  }
  .canvas .canvas_copy_subtitle {
    transform: rotate(270deg) translateY(-100%) translateX(-100%);
  }
  .canvas .rect-gradient {
    -webkit-animation: draw-line 3s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    animation: draw-line 3s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  }
  .canvas_copy {
    width: 100%;
    padding: 10px;
  }

  .canvas_copy_title {
    font-size: 30px;
    line-height: 1;
    display: block;
    text-align: center;
    transform: translateX(-80px);
    transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
    color: #fff;
  }
  .canvas:hover .canvas_copy_title_pre,
  .canvas:hover .canvas_copy_subtitle_pre,
  .canvas:hover .canvas_copy_details_pre,
  .canvas:hover .canvas_img_pre {
    opacity: 0;
  }
  .canvas_copy_title_pre,
  .canvas_copy_subtitle_pre,
  .canvas_copy_details_pre {
    opacity: 0 !important;
  }
  .canvas .canvas_img-wrapper {
    background: linear-gradient(to right, #434343 0%, black 100%);
  }
  .canvas .canvas_img {
    opacity: 0.4;
  }
  .canvas .canvas_copy_pre {
    opacity: 0;
  }

  .canvas_copy_details {
    font-size: 14px;
  }
}
.btn-back-fix {
  background: #f1f1f1;
  color: #000;
  position: relative;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 20px;
  text-decoration: none;
  border-radius: 50%;
}