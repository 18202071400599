.footer {
  background: linear-gradient(105deg, #fff, #fff);
  padding: 20px;
  border-bottom: "1px solid #000";
}
/*END FOOTER SOCIAL DESIGN*/
.single_footer {
}
@media only screen and (max-width: 768px) {
  .single_footer {
    margin-bottom: 30px;
  }
}
.single_footer .title-first {
  color: #6e6e73;
  margin-top: 0;
  // margin-bottom: 25px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
}
.single_footer .desc-first {
  color: #6e6e73;
  margin-top: 0;
  // margin-bottom: 25px;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 20px;
}
.single_footer .copyright {
  color: #6e6e73;
  margin-top: 0;
  // margin-bottom: 25px;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 18px;
}
// .single_footer h4::after {
// content: "";
// display: block;
// height: 2px;
// width: 40px;
// background: #fff;
// margin-top: 20px;
// }

/*START NEWSLETTER CSS*/
.subscribe {
  display: block;
  position: relative;
  // margin-top: 15px;
  width: 100%;
}

.subscribe__btn i {
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 768px) {
  .subscribe__btn {
    right: 0px;
  }
}

.subscribe__btn:hover i {
  color: #ff3666;
}
button {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
}
/*END NEWSLETTER CSS*/

/*START SOCIAL PROFILE CSS*/
.social_profile {
  margin-top: 40px;
}
.social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.social_profile ul li {
  float: left;
}
.social_profile ul li a {
  text-align: center;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  margin: 0px 5px;
  font-size: 18px;
  color: #000;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
}
@media only screen and (max-width: 768px) {
  .social_profile ul li a {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .social_profile ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
.social_profile ul li a:hover {
  background: #ff3666;
  border: 1px solid #ff3666;
  color: #000;
  border: 0px;
}
/*END SOCIAL PROFILE CSS*/
.container-footer {
  border-top: 1px solid #6e6e73;
  margin-top: 20px;
  padding-top: 20px;
}
.copyright {
  margin-top: 10px;
  color: #6e6e73;
  font-size: 15px;
  text-align: center;
  border-right: 1px solid #6e6e73;
}
.copyright-custom {
  margin-top: 10px;
  color: #6e6e73;
  font-size: 15px;
  text-align: center;
  text-decoration: none!important;

}
@media (max-width: "600px") {
  .copyright {
    margin-top: 10px;
    color: #6e6e73;
    font-size: 15px;
    text-align: center;
    border: 1px solid #6e6e73;
    padding: 10px;
    &:hover {
      background-color: #000;
      color: #fff;
      font-weight: 600;
    }
  }

  .copyright-custom {
    margin-top: 10px;
    color: #6e6e73;
    font-size: 15px;
    text-align: center;
    border: 1px solid #6e6e73;
    padding: 10px;
    &:hover {
      background-color: #000;
      color: #fff;
      font-weight: 600;
    }
  }
}
.copyright a {
  color: #6e6e73;
  transition: all 0.2s ease 0s;
  text-decoration: none;
}
.copyright a:hover {
  color: #ff3666;
}

.copyright-custom a {
  color: #6e6e73;
  transition: all 0.2s ease 0s;
  text-decoration: none;
}
.copyright-custom a:hover {
  color: #ff3666;
}
// .border-right-footer{
//     border-right: 1px solid #6E6E73;
// }
